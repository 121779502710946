.main{
    width: 100vw;
    height: 100vh;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.errormes{
  width: 40vw;
  height: 5vh;
  display:flex;
  justify-content: center;
  align-items: center;
}

.content{
  width: 35vw;
  height: 65vh;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.downbar{
  margin-top: 10px;
  margin-left: 50px;
  width: 50vw;
  height: 8vh;
  display: flex;
}

.rightdownbar{
  margin-left: auto;
}

.alert{
  color: red;
}