

.block{
    width: 30vw;
    display: flex;
    flex-direction: row;
}
.left{
    width: 50%;
}
.blocktable{
    width: 30vw;
    display: flex;
    margin-top: 20px;
    flex-direction: row;
    align-items: flex-start;
}
.top{
    margin-top: 0;
}
.table{
    border: 1px solid black;

}
.listitemcontainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    width: 15vw;
    padding-left: 1vw;
    padding-right: 2vw;
}
.listitem{
    align-self: center;
    width: 50%;
}
.toright{
    text-align: end;
    /* align-text: right; */
}