.block{
    display: flex;
    flex-direction: row;
}
.data{
    overflow-y: scroll;
    height: 40vh;
    width: 35vw;
}
.left{
    min-width: 200px;
}
.right{
    min-width: 250px;
}
